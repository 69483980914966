<template>
  <div class="user body-bcg">
    <a-breadcrumb class="breadcrum">
      <template v-if="user !== null && user.SkinIdentifier !== 'pg'">
        <a-breadcrumb-item>
          <a href="/">{{
            client ? client.ClientName : ""
          }}</a></a-breadcrumb-item
        >
        <a-breadcrumb-item
          ><router-link to="/user-management"
            >User Management</router-link
          ></a-breadcrumb-item
        >
        <a-breadcrumb-item> {{ pageTitle }}</a-breadcrumb-item>
      </template>
    </a-breadcrumb>

    <div class="page-padding">
      <div class="page-title">
        <icon-base
          :width="32"
          :height="33"
          :viewBox1="25"
          :viewBox2="25"
          iconName="users"
          iconColor="transparent"
          iconStroke="#4318FF"
          ><icon-users
        /></icon-base>
        <div class="page-title__text">
          <h1>{{ pageTitle }}</h1>
          <span>User Details</span>
        </div>
      </div>

      <div
        class="box-wrapper box-wrapper--settings-form mb-25 max-w-970"
        :class="{ 'show-overlay': saveLoadingStatus }"
      >
        <a-row :gutter="16" class="mb-25">
          <a-col :span="12"><h2>General information</h2></a-col>
          <a-col :span="12" class="flex justify-end"
            ><span
              class="user__item-title-info"
              v-if="informationsSuccessUpdated"
            >
              <font-awesome-icon icon="check" />Informations are success
              updated!</span
            ></a-col
          >
        </a-row>
        <div class="box-wrapper__inner">
          <div class="input-group">
            <label class="lineLable">First Name*</label>
            <div class="position-r">
              <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton>
              <a-input
                placeholder=""
                v-model="userInfo.firstName"
                :class="{
                  error: userInfo.firstName === '' && emptyFieldsValidation,
                }"
              />
            </div>
          </div>
          <div class="input-group">
            <label class="lineLable">Last Name*</label>
            <div class="position-r">
              <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton
              ><a-input
                placeholder=""
                v-model="userInfo.lastName"
                :class="{
                  error: userInfo.lastName === '' && emptyFieldsValidation,
                }"
              />
            </div>
          </div>

          <div class="input-group">
            <label class="lineLable">Email*</label>
            <div class="position-r">
              <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton
              ><a-input
                placeholder=""
                v-model="userInfo.email"
                :class="{
                  error: userInfo.email === '' && emptyFieldsValidation,
                }"
              />
            </div>
          </div>
          <div class="input-group">
            <label class="lineLable">Company*</label>
            <div class="position-r">
              <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton
              ><a-input
                placeholder=""
                v-model="userInfo.company"
                :class="{
                  error: userInfo.company === '' && emptyFieldsValidation,
                }"
              />
            </div>
          </div>

          <div class="input-group">
            <label class="lineLable">Job title*</label>
            <div class="position-r">
              <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton
              ><a-input
                placeholder=""
                v-model="userInfo.jobTitle"
                :class="{
                  error: userInfo.jobTitle === '' && emptyFieldsValidation,
                }"
              />
            </div>
          </div>
          <div class="input-group">
            <label class="lineLable">User Type*</label>
            <div class="position-r">
              <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton
              ><a-select
                class="fullWidth"
                option-filter-prop="children"
                show-search
                :key="selectDropdownsKey"
                @change="selectUserType"
                placeholder="Please select"
                :class="{
                  error: userInfo.UserTypeID === '' && emptyFieldsValidation,
                }"
                :default-value="userInfo.UserTypeID || undefined"
              >
                <a-select-option
                  v-for="(type, index) in userTypes"
                  :key="index"
                  :value="type.id"
                >
                  {{ type.title }}
                </a-select-option>
              </a-select>
            </div>
          </div>

          <div class="input-group" v-if="masterAccount">
            <label class="lineLable">Account type*</label>
            <div class="position-r">
              <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton>
              <a-select
                style="width: 100%"
                placeholder="Please select"
                :filterOption="false"
                @change="selectAccountType"
                :key="Math.random()"
                :class="{
                  error: userInfo.AccountID === '' && emptyFieldsValidation,
                }"
                :default-value="userInfo.AccountID || undefined"
              >
                <a-select-option
                  v-for="(account, index) in accountTypes"
                  :key="index"
                  :value="account.AccountId"
                >
                  {{ account.AccountName }}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <div class="input-group">
            <label class="lineLable">Client*</label>
            <div class="position-r">
              <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton>
              <a-select
                v-model="userInfo.ClientIDs"
                :options="clients"
                style="width: 100%"
                mode="multiple"
                placeholder="Please select"
                :filterOption="false"
                :class="{
                  error:
                    userInfo.ClientIDs.length === 0 && emptyFieldsValidation,
                }"
              >
              </a-select>
            </div>
          </div>
        </div>
      </div>

      <div
        :class="{ 'show-overlay': saveLoadingStatus }"
        class="box-wrapper box-wrapper--settings-form mb-25 max-w-970"
      >
        <h2 class="mb-25">Password</h2>
        <div class="box-wrapper__inner">
          <div class="input-group">
            <label class="lineLable">Password*</label>
            <div class="position-r">
              <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton>
              <div
                @click="togglePassword"
                v-show="userInfo.password !== null || repeatPassword !== null"
                class="icon fixRight iconBtn"
              >
                <font-awesome-icon :icon="pswdVisible ? 'eye-slash' : 'eye'" />
              </div>
              <a-input
                placeholder=""
                :type="pswdType"
                v-model="userInfo.password"
                :class="{
                  error:
                    (userInfo.password === null || userInfo.password === '') &&
                    emptyFieldsValidation,
                }"
              />
            </div>
          </div>
          <div class="input-group">
            <label class="lineLable">Repeat Password*</label>
            <div class="position-r">
              <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton
              ><a-input
                placeholder=""
                :type="pswdType"
                v-model="repeatPassword"
                :class="{
                  error:
                    (repeatPassword === null || repeatPassword === '') &&
                    emptyFieldsValidation,
                }"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="max-w-970 flex justify-between">
        <div>
          <ul class="validation-list" v-if="formValidations.length > 0">
            <li v-for="(item, index) in formValidations" :key="index">
              {{ item }}
            </li>
          </ul>
        </div>
        <div class="flex justify-end">
          <a-button class="mr-10" @click="cancel"> Cancel </a-button>
          <a-button
            type="primary"
            @click="formValidation"
            class="flex items-center"
          >
            <div class="flex" v-if="saveLoadingStatus">
              <i class="circle-loader"></i> Please wait...
            </div>
            <span v-else>Save</span>
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from "@/components/general/Skeleton.vue";
import * as jsUtils from "@/utils/jsUtils.js";
import * as wsUtils from "@/utils/wsUtils.js";
import { store } from "@/utils/store.js";
import IconBase from "@/components/general/IconBase.vue";
import IconUsers from "@/components/icons/IconUsers.vue";
import _ from "lodash";

export default {
  name: "User",
  components: {
    Skeleton,
    IconBase,
    IconUsers,
  },
  data() {
    return {
      user: null,
      userInfo: {
        firstName: "",
        lastName: "",
        email: "",
        company: "",
        jobTitle: "",
        password: "",
        planID: "",
        AccountID: "",
        ClientIDs: [],
        UserTypeID: "",
        CreatedBy: "",
      },
      repeatPassword: "",
      accountTypes: [],
      userTypes: [
        {
          title: "User",
          id: 1,
        },
        {
          title: "Admin",
          id: 2,
        },
      ],
      planTypes: [
        {
          title: "Basic",
          id: 1,
        },
        {
          title: "Enterprise",
          id: 2,
        },
        {
          title: "Agency",
          id: 3,
        },
      ],
      showSkeleton: false,
      selectDropdownsKey: 1,
      informationsSuccessUpdated: false,
      clients: [],
      emptyFieldsValidation: false,
      formValidations: [],
      client: null,
      saveLoadingStatus: false,
      pswdVisible: false,
    };
  },
  watch: {},
  methods: {
    selectAccountType(value) {
      this.userInfo.AccountID = value;
      this.userInfo.ClientIDs = [];
      this.getAccountClients(value);
    },
    selectUserType(value) {
      this.userInfo.UserTypeID = value;
    },
    selectPlanType(value) {
      this.userInfo.planID = value;
    },
    formValidation() {
      this.formValidations = [];
      this.emptyFieldsValidation = true;

      this.passwordValidation();
      this.emailValidation();

      this.emptyFields();

      if (this.formValidations.length === 0) {
        this.saveLoadingStatus = true;
        this.emptyFieldsValidation = false;
        if (this.$route.name === "edit-user") {
          this.editUser();
        } else {
          this.createNewUser();
        }
      }
    },
    passwordValidation() {
      const passwordFormat = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

      if (this.userInfo.password !== null && this.userInfo.password !== "") {
        if (this.userInfo.password.length < 8)
          this.formValidations.push("Password must be atleast 8 characters!");
        if (!/\d/.test(this.userInfo.password))
          this.formValidations.push("Password must contain atleast 1 number!");
        if (!/[A-Z]/.test(this.userInfo.password))
          this.formValidations.push(
            "Password must contain atleast 1 Capital Case!"
          );
        if (!passwordFormat.test(this.userInfo.password))
          this.formValidations.push(
            "Password must contain atleast 1 Special Character!"
          );
        if (this.userInfo.password !== this.repeatPassword)
          this.formValidations.push(
            "New Password and Repeat Password do not match"
          );
      }
    },
    emailValidation() {
      const emailFormat =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.userInfo.email !== null && this.userInfo.email !== "") {
        if (!emailFormat.test(this.userInfo.email)) {
          this.formValidations.push(
            "The email address you entered is not valid!"
          );
        }
      }
    },
    emptyFields() {
      if (this.$route.name !== "edit-user") {
        for (var key in this.userInfo) {
          if (
            this.userInfo[key] === "" ||
            this.userInfo[key] === [] ||
            this.userInfo[key] === null
          ) {
            this.formValidations.push("All fields are required!");
            break;
          }
        }
      }
    },
    createNewUser() {
      const userInfo = Object.assign({}, this.userInfo, {
        ClientIDs: this.userInfo.ClientIDs.toString(),
      });

      const createUser = new Promise((resolve) => {
        wsUtils.CreateUser(userInfo, resolve);
      });

      createUser.then((data) => {
        this.saveLoadingStatus = false;
        if (data.Status === 1) {
          this.$notification["warning"]({
            message: `Oops`,
            description:
              "The user already exists, please change email or contact Hotspex support.",
            placement: "bottomLeft",
            duration: 5,
          });

          this.emptyFieldsValidation = true;
        }

        if (data.Status === 0) {
          this.success("A new user is created successfully.");
        }
      });
    },
    editUser() {
      const userInfo = Object.assign({}, this.userInfo, {
        ClientIDs: this.userInfo.ClientIDs.toString(),
      });

      delete userInfo.planID;
      userInfo.password === null ? (userInfo.password = "") : userInfo.password;

      const updateUser = new Promise((resolve) => {
        wsUtils.UpdateUser(
          { userdata: userInfo, UserID: store.userInfo.userID },
          resolve
        );
      });

      updateUser.then((data) => {
        if (data.Status === 1) {
          this.success("A user is updated successfully.");
        }
        this.saveLoadingStatus = false;
      });
    },
    togglePassword() {
      this.pswdVisible = !this.pswdVisible;
    },
    resetUserInfo() {
      this.emptyFieldsValidation = false;
      this.userInfo = {
        firstName: "",
        lastName: "",
        email: "",
        company: "",
        jobTitle: "",
        password: "",
        planID: "",
        AccountID: "",
        ClientIDs: [],
        UserTypeID: "",
        CreatedBy: "",
      };
      this.selectDropdownsKey = Math.random();
    },
    getAccountTypes() {
      const GetAccount = new Promise((resolve) => {
        wsUtils.GetAccount(this.user.EncrypteduserID, resolve);
      });
      GetAccount.then((data) => {
        this.accountTypes = data;
      });
    },
    getAccountClients(accountId) {
      const accountClient = new Promise((resolve) => {
        wsUtils.GetAccountClient(
          { userid: this.user.EncrypteduserID, accountID: accountId },
          resolve
        );
      });
      accountClient.then((data) => {
        let dataClients = data.map((item) => {
          return { value: item.clientID, label: item.clientName };
        });
        let chosenClients = [];

        if (store.userInfo !== null) {
          chosenClients = store.userInfo.ClientDetails.map((item) => {
            return { value: item.clientID, label: item.clientName };
          });
        }

        this.clients = _.unionBy(dataClients, chosenClients, "value");
      });
    },
    fieldsControl() {
      if (this.$route.name === "edit-user") {
        this.showSkeleton = true;
        if (store.userInfo !== null) {
          setTimeout(() => {
            this.showSkeleton = false;
          }, 400);

          this.userInfo = {
            firstName: store.userInfo.firstName,
            lastName: store.userInfo.lastName,
            email: store.userInfo.EmailId,
            company: store.userInfo.CompanyName,
            jobTitle: store.userInfo.jobTitle,
            password: store.userInfo.NewPassword,
            planID: store.userInfo.planID,
            AccountID: store.userInfo.accountID,
            ClientIDs: store.userInfo.ClientDetails.map((item) => {
              return item.clientID;
            }),
            UserTypeID: store.userInfo.userTypeID,
            CreatedBy: store.userInfo.createdBy,
          };
          this.repeatPassword = store.userInfo.NewPassword;
          this.selectDropdownsKey = Math.random();
        } else {
          this.$notification["warning"]({
            message: `Oops`,
            description: "You need to select a user you want to change.",
            placement: "bottomLeft",
            duration: 5,
          });
          this.$router.push("user-management");
        }
      } else {
        this.resetUserInfo();
      }
    },
    getCurrentUserInfo() {
      const userInfo = new Promise((resolve) => {
        wsUtils.GetUserInfo(this.user.EncrypteduserID, resolve);
      });

      userInfo.then((data) => {
        this.userInfo.company = data[0].CompanyName;
      });
    },
    success(message) {
      let _this = this;
      this.$notification["success"]({
        message: `Well done!`,
        description: message,
        placement: "bottomLeft",
        duration: 5,
      });
      _this.$router.push("user-management");
      this.resetUserInfo();
      this.selectDropdownsKey = Math.random();
    },
    cancel() {
      this.$router.go(-1);
    },
    checkUserAndAddType() {
      const conditionalIndex = this.userTypes.findIndex(
        (type) => type.id === 3
      );

      if (this.user.planID === 4 && conditionalIndex === -1) {
        this.userTypes.push({
          title: "Demo",
          id: 3,
        });
      } else if (conditionalIndex !== -1 && this.user.planID !== 4) {
        this.userTypes.splice(conditionalIndex, 1);
      }
    },
  },
  computed: {
    pswdType() {
      return this.pswdVisible ? "text" : "password";
    },
    pageTitle() {
      return this.$route.name === "edit-user"
        ? "Edit user"
        : "Create a new user";
    },
    masterAccount() {
      return this.user && this.user.planID === 4 ? true : false;
    },
  },
  activated() {
    this.formValidations = [];
    this.fieldsControl();

    this.client = jsUtils.getCurClient();
    this.user = jsUtils.getUserInfo();

    this.getAccountClients();
    this.getAccountTypes();
    if (this.$route.name !== "edit-user") {
      this.userInfo.CreatedBy = this.user.firstName;
      this.getCurrentUserInfo();
    }
    this.userInfo.planID = this.user.planID;
    this.userInfo.AccountID = this.user.AccountId;
    this.checkUserAndAddType();
  },
};
</script>

<style lang="scss" scoped>
.user {
  position: relative;
  text-align: left;
  &__item-title-info {
    color: var(--hsblue);
    text-align: right;
    font-size: 14px;
    svg {
      margin-right: 4px;
      width: 12px;
    }
  }
}
</style>
