<template>
  <div class="management-system body-bcg">
    <a-breadcrumb class="breadcrum">
      <template v-if="user !== null && user.SkinIdentifier !== 'pg'">
        <a-breadcrumb-item>
          <router-link to="/">{{
            client ? client.ClientName : ""
          }}</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item
          ><router-link to="/account-management"
            >Account Management</router-link
          ></a-breadcrumb-item
        >
      </template>
    </a-breadcrumb>

    <div class="management-system__content page-padding">
      <div class="page-title">
        <icon-base
          :width="32"
          :height="33"
          :viewBox1="25"
          :viewBox2="25"
          iconName="users"
          iconColor="transparent"
          iconStroke="#4318FF"
          ><icon-users
        /></icon-base>
        <div class="page-title__text">
          <h1>Account Management</h1>
          <span>General Account Information</span>
        </div>
      </div>

      <div v-if="!this.loadingAccountClients && !this.loadingUserDropdown">
        <div class="flex items-end justify-between management-system__tools">
          <div class="flex gap-15 flex-1">
            <div class="input-group input-group--small max-w-200 w-full">
              <label>Search accounts</label>
              <div
                class="position-r search-field"
                :class="{ focus: searchActive }"
              >
                <icon-base
                  class="search-icon"
                  :width="20"
                  :height="20"
                  :viewBox1="64"
                  :viewBox2="64"
                  iconName="searchThin"
                  iconColor="transparent"
                  iconStroke="#000"
                  ><icon-search-thin
                /></icon-base>
                <a-input
                  :class="{ active: searchParametars != '' }"
                  v-model="searchParametars"
                  placeholder="Insert account name"
                  @focus="searchActive = true"
                  @blur="searchActive = false"
                >
                  <a-icon
                    slot="addonAfter"
                    theme="filled"
                    type="close-circle"
                    v-show="searchParametars != ''"
                    @click="searchParametars = ''"
                /></a-input>
              </div>
            </div>
          </div>

          <a-button type="primary" @click="openAccountManagementModal"
            >Create a new account
          </a-button>
        </div>

        <div class="management-system__table box-wrapper">
          <a-table
            tableLayout="auto"
            class="custom-table"
            :columns="columns"
            :row-key="(record) => record.clientID"
            :loading="loading"
            :pagination="pagination"
            @change="handleTableChange"
            :customRow="clickTableRow"
            :data-source="searchAccountsList"
          >
            <span slot="UsersDetails" slot-scope="record">
              <a-tag v-for="item in record.clientUsers" :key="item.userID">
                {{ item.userName }}
              </a-tag>
            </span>
            <span slot="action" slot-scope="record">
              <p class="row-caption">Edit Account</p>
              <a-popover
                v-model="toggleVisible[record.clientID]"
                trigger="click"
              >
                <template slot="content">
                  <div class="a-popover-content">
                    <button
                      class="text-btn text-btn--dark text-left"
                      type="link"
                      @click="
                        editAccountTrigger(record),
                          (toggleVisible[record.clientID] = false)
                      "
                    >
                      <span class="ant-btn-icon-wrapper">
                        <font-awesome-icon icon="edit" />
                      </span>
                      Edit
                    </button>
                    <button
                      class="text-btn text-btn--dark text-left"
                      type="link"
                      @click="
                        deleteAccount(record),
                          (toggleVisible[record.clientID] = false)
                      "
                    >
                      <span class="ant-btn-icon-wrapper">
                        <font-awesome-icon icon="trash-alt" />
                      </span>
                      Delete
                    </button>
                  </div>
                </template>
                <a
                  class="
                    ant-dropdown-link
                    a-popover-trigger
                    js-a-popover-trigger
                  "
                  :class="{
                    'a-popover-trigger--active': toggleVisible[record.clientID],
                  }"
                  :data-sid="record.clientID"
                >
                  <font-awesome-icon icon="ellipsis-v" />
                </a>
              </a-popover>
            </span>
          </a-table>
        </div>
      </div>
      <Loader text="Loading data..." :paddingTop="150" v-else></Loader>
    </div>
    <a-modal
      :title="editAccountMode ? 'Edit account' : 'Create a new account'"
      :visible="accountManagementModal"
      @cancel="closeAccountManagementModal"
      :footer="null"
      :width="680"
    >
      <a-row :gutter="16" class="mb-25">
        <a-col :span="12">
          <div class="input-group input-group--small mb-15">
            <label class="lineLable">Account name*</label>
            <div class="position-r">
              <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton
              ><a-input
                placeholder=""
                v-model="accountInfo.clientName"
                :class="{
                  'error-field':
                    accountInfo.clientName === '' && emptyFieldsValidation,
                }"
              />
            </div>
          </div>

          <div class="input-group input-group--small mb-15">
            <label class="lineLable">Contact name*</label>
            <div class="position-r">
              <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton
              ><a-input
                placeholder=""
                v-model="accountInfo.ContactName"
                :class="{
                  'error-field':
                    accountInfo.ContactName === '' && emptyFieldsValidation,
                }"
              />
            </div>
          </div>
          <div class="input-group input-group--small">
            <label class="lineLable">E-mail*</label>
            <div class="position-r">
              <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton
              ><a-input
                placeholder=""
                v-model="accountInfo.contactEmail"
                :class="{
                  'error-field':
                    accountInfo.contactEmail === '' && emptyFieldsValidation,
                }"
              />
            </div>
          </div>
        </a-col>
        <a-col :span="12" class="input-group input-group--small"
          ><label class="lineLable">Users*</label>
          <div class="position-r">
            <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton>
            <a-select
              v-model="accountInfo.userIDs"
              :options="clients"
              style="width: 100%"
              mode="multiple"
              :filterOption="false"
              :class="{
                'error-field':
                  accountInfo.userIDs.length === 0 && emptyFieldsValidation,
              }"
            >
            </a-select></div
        ></a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="12"
          ><ul
            class="validation-list"
            :class="{
              'validation-list--no-list-type': formValidations.length === 1,
            }"
            v-if="formValidations.length > 0"
          >
            <li v-for="(item, index) in formValidations" :key="index">
              {{ item }}
            </li>
          </ul>
        </a-col>
        <a-col :span="12" class="flex justify-end">
          <a-button
            @click="closeAccountManagementModal()"
            class="flex items-center mr-10"
          >
            <span>Cancel</span>
          </a-button>
          <a-button
            type="primary"
            @click="formValidation"
            class="flex items-center"
            :disabled="disableSaveBtn"
          >
            <div class="flex" v-if="saveLoadingStatus">
              <i class="circle-loader"></i> Please wait...
            </div>
            <span v-else>Save</span>
          </a-button></a-col
        >
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import * as jsUtils from "@/utils/jsUtils.js";
import * as wsUtils from "@/utils/wsUtils.js";
import Loader from "@/components/general/Loader.vue";
import IconBase from "@/components/general/IconBase.vue";
import IconUsers from "@/components/icons/IconUsers.vue";
import IconSearchThin from "@/components/icons/IconSearchThin.vue";
import _ from "lodash";

const columns = [
  {
    title: "Account name",
    key: "clientName",
    dataIndex: "clientName",
  },
  {
    title: "Contact name",
    key: "contactName",
    dataIndex: "contactName",
  },
  {
    title: "E-mail",
    key: "contactEmail",
    dataIndex: "contactEmail",
  },
  {
    title: "Users",
    scopedSlots: { customRender: "UsersDetails" },
  },
  {
    title: "Action",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "AccountManagement",
  components: {
    Loader,
    IconBase,
    IconUsers,
    IconSearchThin,
  },
  data() {
    return {
      columns,
      searchParametars: "",
      client: null,
      user: null,
      clients: [],
      loading: false,
      loadingUserDropdown: true,
      loadingAccountClients: true,
      pagination: {},
      toggleVisible: [],
      accountManagementModal: false,
      showSkeleton: false,
      accountInfo: {
        clientName: "",
        ContactName: "",
        contactEmail: "",
        userIDs: [],
      },
      tempAccountInfo: {},
      emptyFieldsValidation: false,
      saveLoadingStatus: false,
      formValidations: [],
      editAccountMode: false,
      accountClients: [],
      activeClientId: null,
      accountInfoChanged: false,
    };
  },
  methods: {
    handleTableChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
    },
    clickTableRow(record) {
      return {
        on: {
          click: (event) => {
            if (
              event === undefined ||
              !event.target.closest(".js-a-popover-trigger")
            ) {
              this.editAccountTrigger(record);
            }
          },
        },
      };
    },
    deleteAccount(record) {
      let _this = this;
      this.$confirm({
        title: "Delete an account",
        content: () => (
          <div>Are you sure you want to delete {record.contactName}?</div>
        ),
        onOk() {
          const deleteAccountClients = new Promise((resolve) => {
            wsUtils.DeleteAccountClients(
              {
                userID: _this.user.EncrypteduserID,
                clientId: record.clientID,
              },
              resolve
            );
          });
          deleteAccountClients.then((data) => {
            if (data.Success === true) {
              _this.success("An account is deleted successfully.");
            }
          });
        },
        okText: "Yes",
      });
    },
    editAccountTrigger(record) {
      this.activeClientId = record.clientID;
      let users = [];
      _.forEach(record.clientUsers, (item) => {
        const userID =
          typeof item.userID === "string"
            ? parseInt(item.userID, 10)
            : item.userID;
        users.push(userID);
      });
      this.accountInfo = {
        ContactName: record.contactName,
        clientName: record.clientName,
        contactEmail: record.contactEmail,
        userIDs: users,
      };
      this.tempAccountInfo = Object.assign({}, this.accountInfo);
      this.editAccountMode = true;
      this.accountManagementModal = true;
    },
    editAccount() {
      const accountInfo = Object.assign({}, this.accountInfo, {
        userIDs: this.accountInfo.userIDs.toString(),
        userID: this.user.EncrypteduserID,
        clientId: this.activeClientId,
      });

      const editAccountClients = new Promise((resolve) => {
        wsUtils.EditAccountClients(accountInfo, resolve);
      });

      editAccountClients.then((data) => {
        if (data.Success === true) {
          this.success("An account is edited successfully.");
        }
      });
    },
    formValidation() {
      this.formValidations = [];
      this.emptyFieldsValidation = true;

      this.emailValidation();
      this.emptyFields();

      if (this.formValidations.length === 0) {
        this.saveLoadingStatus = true;
        this.emptyFieldsValidation = false;
        if (this.editAccountMode) {
          this.editAccount();
        } else {
          this.addAccount();
        }
      }
    },
    emailValidation() {
      const emailFormat =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (
        this.accountInfo.contactEmail !== null &&
        this.accountInfo.contactEmail !== ""
      ) {
        if (!emailFormat.test(this.accountInfo.contactEmail)) {
          this.formValidations.push(
            "The email address you entered is not valid!"
          );
        }
      }
    },
    emptyFields() {
      for (var key in this.accountInfo) {
        if (
          this.accountInfo[key] === "" ||
          this.accountInfo[key].length === 0 ||
          this.accountInfo[key] === null
        ) {
          this.formValidations.push("All fields are required!");
          break;
        }
      }
    },
    closeAccountManagementModal() {
      this.accountManagementModal = false;
      this.resetAccountInfo();
      this.editAccountMode = false;
      this.formValidations = [];
      this.emptyFieldsValidation = false;
    },
    openAccountManagementModal() {
      this.accountManagementModal = true;
    },
    setUserDropdown() {
      const getUserDropdown = new Promise((resolve) => {
        wsUtils.GetUserDropdown({ userid: this.user.EncrypteduserID }, resolve);
      });
      getUserDropdown.then((data) => {
        this.clients = data.map((item) => {
          return { value: item.UserId, label: item.UserName };
        });
        this.loadingUserDropdown = false;
      });
    },
    getAccountClients() {
      const getAccountClients = new Promise((resolve) => {
        wsUtils.GetAccountClients(
          { userid: this.user.EncrypteduserID },
          resolve
        );
      });
      getAccountClients.then((data) => {
        this.accountClients = data;
        const pagination = { ...this.pagination };
        pagination.total = data.length;
        pagination.hideOnSinglePage = true;
        this.pagination = pagination;
        this.loadingAccountClients = false;
      });
    },
    addAccount() {
      const accountInfo = Object.assign({}, this.accountInfo, {
        userIDs: this.accountInfo.userIDs.toString(),
        userID: this.user.EncrypteduserID,
      });

      const addClientManagement = new Promise((resolve) => {
        wsUtils.AddClientManagement(accountInfo, resolve);
      });
      addClientManagement.then((data) => {
        if (data.Success === true) {
          this.success("A new account is created successfully.");
        }
      });
    },
    success(message) {
      this.$notification["success"]({
        message: `Well done!`,
        description: message,
        placement: "bottomLeft",
        duration: 4,
      });
      this.resetAccountInfo();
      this.saveLoadingStatus = false;
      this.accountManagementModal = false;
      this.getAccountClients();
    },
    resetAccountInfo() {
      this.accountInfo = {
        clientName: "",
        ContactName: "",
        contactEmail: "",
        userIDs: [],
      };
      this.tempAccountInfo = {};
    },
  },
  computed: {
    searchAccountsList() {
      return this.accountClients.filter((item) => {
        return this.searchParametars
          .toLowerCase()
          .split(" ")
          .every(
            (v) =>
              item.clientName.toLowerCase().includes(v) ||
              item.contactName.toLowerCase().includes(v) ||
              item.contactEmail.toLowerCase().includes(v)
          );
      });
    },
    disableSaveBtn() {
      let objectsCompare = _.isEqual(this.accountInfo, this.tempAccountInfo);
      return objectsCompare;
    },
  },
  mounted() {},
  created() {
    this.client = jsUtils.getCurClient();
    this.user = jsUtils.getUserInfo();
    this.setUserDropdown();
    this.getAccountClients();
  },
  activated() {},
};
</script>

<style lang="scss" scoped>
.a-popover-content {
  display: flex;
  flex-direction: column;
  margin: 0 -2px;
  .ant-btn {
    color: var(--hsgreyDark);
    height: 22px;
    margin-bottom: 5px;
    padding: 0;
    text-align: left;
    display: flex;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      color: var(--hsblueDark);
    }
    .ant-btn-icon-wrapper {
      min-width: 18px;
      display: block;
    }
  }
}

::v-deep .custom-table {
  tbody {
    td {
      &:first-child {
        width: 200px;
      }
    }
  }
}

::v-deep .input-group--small .ant-select-selection {
  min-height: 205px;
}
</style>
