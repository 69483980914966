<template>
  <div class="management-system body-bcg">
    <a-breadcrumb class="breadcrum">
      <a-breadcrumb-item>
        <router-link to="/">{{ client ? client.ClientName : "" }}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item
        ><router-link to="/client-management"
          >Client Management</router-link
        ></a-breadcrumb-item
      >
    </a-breadcrumb>
    <div class="management-system__content page-padding">
      <div class="page-title">
        <icon-base
          :width="32"
          :height="33"
          :viewBox1="25"
          :viewBox2="25"
          iconName="users"
          iconColor="transparent"
          iconStroke="#4318FF"
          ><icon-users
        /></icon-base>
        <div class="page-title__text">
          <h1>Client Management</h1>
          <span>General Client Information</span>
        </div>
      </div>
      <div v-if="!this.loadingClients">
        <div class="flex items-end justify-between management-system__tools">
          <div class="flex gap-15 flex-1">
            <div class="input-group input-group--small max-w-200 w-full">
              <label>Search clients</label>
              <div
                class="position-r search-field"
                :class="{ focus: searchActive }"
              >
                <icon-base
                  class="search-icon"
                  :width="20"
                  :height="20"
                  :viewBox1="64"
                  :viewBox2="64"
                  iconName="searchThin"
                  iconColor="transparent"
                  iconStroke="#000"
                  ><icon-search-thin
                /></icon-base>
                <a-input
                  :class="{ active: searchParametars != '' }"
                  v-model="searchParametars"
                  placeholder="Insert client name"
                  @focus="searchActive = true"
                  @blur="searchActive = false"
                >
                  <a-icon
                    slot="addonAfter"
                    theme="filled"
                    type="close-circle"
                    v-show="searchParametars != ''"
                    @click="searchParametars = ''"
                /></a-input>
              </div>
            </div>
          </div>

          <a-button type="primary" @click="openClientManagementModal"
            >Create a new client
          </a-button>
        </div>
        <div class="management-system__table box-wrapper">
          <a-table
            tableLayout="auto"
            class="custom-table"
            :columns="columns"
            :row-key="(record) => record.clientID"
            :loading="loading"
            :pagination="pagination"
            @change="handleTableChange"
            :customRow="clickTableRow"
            :data-source="searchClientsList"
          >
            <span slot="UsersDetails" slot-scope="record">
              <a-tag v-for="item in record.clientUsers" :key="item.userID">
                {{ item.userName }}
              </a-tag>
            </span>
            <span slot="action" slot-scope="record">
              <p class="row-caption">Edit Client</p>
              <a-popover
                v-model="toggleVisible[record.clientID]"
                trigger="click"
              >
                <template slot="content">
                  <div class="a-popover-content">
                    <a-button
                      type="link"
                      @click="
                        editClientTrigger(record),
                          (toggleVisible[record.clientID] = false)
                      "
                    >
                      <span class="ant-btn-icon-wrapper">
                        <font-awesome-icon icon="edit" />
                      </span>
                      Edit
                    </a-button>
                    <a-button
                      type="link"
                      @click="
                        deleteAccount(record),
                          (toggleVisible[record.clientID] = false)
                      "
                    >
                      <span class="ant-btn-icon-wrapper">
                        <font-awesome-icon icon="trash-alt" />
                      </span>
                      Delete
                    </a-button>
                  </div>
                </template>
                <a
                  class="
                    ant-dropdown-link
                    a-popover-trigger
                    js-a-popover-trigger
                  "
                  :class="{
                    'a-popover-trigger--active': toggleVisible[record.clientID],
                  }"
                  :data-sid="record.clientID"
                >
                  <font-awesome-icon icon="ellipsis-v" />
                </a>
              </a-popover>
            </span>
          </a-table>
        </div>
      </div>
      <Loader text="Loading data..." :paddingTop="150" v-else></Loader>
    </div>
    <a-modal
      :title="editClientMode ? 'Edit client' : 'Create a new client'"
      :visible="clientManagementModal"
      @cancel="closeClientManagementModal"
      :footer="null"
      :width="680"
    >
      <a-row :gutter="16" class="mb-25">
        <a-col :span="12">
          <div class="input-group input-group--small mb-15">
            <label class="lineLable">Client name*</label>
            <div class="position-r">
              <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton
              ><a-input
                placeholder=""
                v-model="clientInfo.clientName"
                :class="{
                  'error-field':
                    clientInfo.clientName === '' && emptyFieldsValidation,
                }"
              />
            </div>
          </div>
          <div class="input-group input-group--small mb-15">
            <label class="lineLable">Contact name*</label>
            <div class="position-r">
              <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton
              ><a-input
                placeholder=""
                v-model="clientInfo.contactName"
                :class="{
                  'error-field':
                    clientInfo.contactName === '' && emptyFieldsValidation,
                }"
              />
            </div>
          </div>
          <div class="input-group input-group--small">
            <label class="lineLable">E-mail*</label>
            <div class="position-r">
              <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton
              ><a-input
                placeholder=""
                v-model="clientInfo.contactEmail"
                :class="{
                  'error-field':
                    clientInfo.contactEmail === '' && emptyFieldsValidation,
                }"
              />
            </div>
          </div>
        </a-col>
        <a-col :span="12">
          <div class="input-group input-group--small">
            <label class="lineLable">Users*</label>
            <div class="position-r">
              <Skeleton :borderRadius="16" v-if="showSkeleton"></Skeleton>
              <a-select
                v-model="clientInfo.UserID"
                :options="clients"
                style="width: 100%"
                mode="multiple"
                :filterOption="false"
                :class="{
                  'error-field':
                    clientInfo.UserID.length === 0 && emptyFieldsValidation,
                }"
              >
              </a-select>
            </div>
          </div>
        </a-col>
      </a-row>

      <a-row :gutter="16">
        <a-col :span="12"
          ><ul
            class="validation-list"
            :class="{
              'validation-list--no-list-type': formValidations.length === 1,
            }"
            v-if="formValidations.length > 0"
          >
            <li v-for="(item, index) in formValidations" :key="index">
              {{ item }}
            </li>
          </ul>
        </a-col>
        <a-col :span="12" class="flex justify-end">
          <a-button
            @click="closeClientManagementModal()"
            class="flex items-center mr-10"
          >
            <span>Cancel</span>
          </a-button>
          <a-button
            type="primary"
            @click="formValidation"
            class="flex items-center"
            :disabled="disableSaveBtn"
          >
            <div class="flex" v-if="saveLoadingStatus">
              <i class="circle-loader"></i> Please wait...
            </div>
            <span v-else>Save</span>
          </a-button></a-col
        >
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import * as jsUtils from "@/utils/jsUtils.js";
import * as wsUtils from "@/utils/wsUtils.js";
import Loader from "@/components/general/Loader.vue";
import _ from "lodash";
import IconBase from "@/components/general/IconBase.vue";
import IconUsers from "@/components/icons/IconUsers.vue";
import IconSearchThin from "@/components/icons/IconSearchThin.vue";

const columns = [
  {
    title: "Client name",
    key: "clientName",
    dataIndex: "clientName",
  },
  {
    title: "Contact name",
    key: "contactName",
    dataIndex: "contactName",
  },
  {
    title: "E-mail",
    key: "contactEmail",
    dataIndex: "contactEmail",
  },
  {
    title: "Users",
    scopedSlots: { customRender: "UsersDetails" },
  },
  {
    title: "Action",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "ClientManagement",
  components: {
    Loader,
    IconBase,
    IconUsers,
    IconSearchThin,
  },
  data() {
    return {
      columns,
      searchParametars: "",
      client: null,
      user: null,
      clients: [],
      loading: false,
      loadingUserDropdown: true,
      loadingClients: true,
      pagination: {},
      toggleVisible: [],
      clientManagementModal: false,
      showSkeleton: false,
      clientInfo: {
        clientName: "",
        contactName: "",
        contactEmail: "",
        UserID: [],
      },
      tempClientInfo: {},
      emptyFieldsValidation: false,
      saveLoadingStatus: false,
      formValidations: [],
      editClientMode: false,
      userClients: [],
      activeClientId: null,
      accountInfoChanged: false,
      searchActive: false,
    };
  },
  methods: {
    handleTableChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
    },
    clickTableRow(record) {
      return {
        on: {
          click: (event) => {
            if (
              event === undefined ||
              !event.target.closest(".js-a-popover-trigger")
            ) {
              this.editClientTrigger(record);
            }
          },
        },
      };
    },
    deleteAccount(record) {
      let _this = this;
      this.$confirm({
        title: "Delete a client",
        content: () => (
          <div>Are you sure you want to delete {record.contactName}?</div>
        ),
        onOk() {
          const deleteClient = new Promise((resolve) => {
            wsUtils.DeleteClient(
              {
                userID: _this.user.EncrypteduserID,
                clientID: record.clientID,
              },
              resolve
            );
          });
          deleteClient.then((data) => {
            if (data.Success === true) {
              _this.success("A client is deleted successfully.");
            } else {
              this.error(
                "Something was wrong, please try again or contact our support."
              );
            }
          });
        },
        okText: "Yes",
      });
    },
    editClientTrigger(record) {
      this.activeClientId = record.clientID;
      let users = [];
      _.forEach(record.clientUsers, (item) => {
        users.push(item.userID);
      });
      this.clientInfo = {
        contactName: record.contactName,
        clientName: record.clientName,
        contactEmail: record.contactEmail,
        UserID: users,
      };
      this.tempClientInfo = Object.assign({}, this.clientInfo);
      this.editClientMode = true;
      this.clientManagementModal = true;
    },
    editClient() {
      const clientInfo = Object.assign({}, this.clientInfo, {
        UserID: this.clientInfo.UserID.toString(),
        clientID: this.activeClientId,
        AccountId: this.user.AccountId,
      });

      const addClient = new Promise((resolve) => {
        wsUtils.AddClient(clientInfo, resolve);
      });

      addClient.then((data) => {
        if (data.Success === true) {
          this.success("A client is edited successfully.");
        } else {
          this.error(
            "Something was wrong, please try again or contact our support."
          );
        }
      });
    },
    formValidation() {
      this.formValidations = [];
      this.emptyFieldsValidation = true;

      this.emailValidation();
      this.emptyFields();

      if (this.formValidations.length === 0) {
        this.saveLoadingStatus = true;
        this.emptyFieldsValidation = false;
        if (this.editClientMode) {
          this.editClient();
        } else {
          this.addClient();
        }
      }
    },
    emailValidation() {
      const emailFormat =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (
        this.clientInfo.contactEmail !== null &&
        this.clientInfo.contactEmail !== ""
      ) {
        if (!emailFormat.test(this.clientInfo.contactEmail)) {
          this.formValidations.push(
            "The email address you entered is not valid!"
          );
        }
      }
    },
    emptyFields() {
      for (var key in this.clientInfo) {
        if (
          this.clientInfo[key] === "" ||
          this.clientInfo[key].length === 0 ||
          this.clientInfo[key] === null
        ) {
          this.formValidations.push("All fields are required!");
          break;
        }
      }
    },
    closeClientManagementModal() {
      this.clientManagementModal = false;
      this.resetClientInfo();
      this.editClientMode = false;
      this.formValidations = [];
      this.emptyFieldsValidation = false;
    },
    openClientManagementModal() {
      this.clientManagementModal = true;
    },
    setUserDropdown() {
      const getUserDropdown = new Promise((resolve) => {
        wsUtils.GetAccountUsers({ userid: this.user.EncrypteduserID }, resolve);
      });
      getUserDropdown.then((data) => {
        this.clients = data.map((item) => {
          return {
            value: item.userID,
            label: item.firstName + " " + item.lastName,
          };
        });
        this.loadingUserDropdown = false;
      });
    },
    getAllUserClients() {
      const getUserClients = new Promise((resolve) => {
        wsUtils.GetUserClients({ userId: this.user.EncrypteduserID }, resolve);
      });
      getUserClients.then((data) => {
        this.userClients = data;
        const pagination = { ...this.pagination };
        pagination.total = data.length;
        pagination.hideOnSinglePage = true;
        this.pagination = pagination;
        this.loadingClients = false;
      });
    },
    addClient() {
      const clientInfo = Object.assign({}, this.clientInfo, {
        UserID: this.clientInfo.UserID.toString(),
        AccountId: this.user.AccountId,
      });

      const addClient = new Promise((resolve) => {
        wsUtils.AddClient(clientInfo, resolve);
      });
      addClient.then((data) => {
        if (data.Success === true) {
          this.success("A new client is created successfully.");
        } else {
          this.error(
            "Something was wrong, please try again or contact our support."
          );
        }
      });
    },
    success(message) {
      this.$notification["success"]({
        message: `Well done!`,
        description: message,
        placement: "bottomLeft",
        duration: 4,
      });
      this.resetClientInfo();
      this.saveLoadingStatus = false;
      this.clientManagementModal = false;
      this.getAllUserClients();
    },
    error(message) {
      this.$notification["error"]({
        message: `Error!`,
        description: message,
        placement: "bottomLeft",
        duration: 4,
      });
      this.saveLoadingStatus = false;
    },
    resetClientInfo() {
      this.clientInfo = {
        clientName: "",
        contactName: "",
        contactEmail: "",
        UserID: [],
      };
      this.tempClientInfo = {};
    },
  },
  computed: {
    searchClientsList() {
      return this.userClients.filter((item) => {
        return this.searchParametars
          .toLowerCase()
          .split(" ")
          .every(
            (v) =>
              item.clientName.toLowerCase().includes(v) ||
              item.contactName.toLowerCase().includes(v) ||
              item.contactEmail.toLowerCase().includes(v)
          );
      });
    },
    disableSaveBtn() {
      let objectsCompare = _.isEqual(this.accountInfo, this.tempClientInfo);
      return objectsCompare;
    },
  },
  mounted() {},
  created() {
    this.client = jsUtils.getCurClient();
    this.user = jsUtils.getUserInfo();
    this.setUserDropdown();
    this.getAllUserClients();
  },
  activated() {},
};
</script>

<style lang="scss" scoped>
.a-popover-content {
  display: flex;
  flex-direction: column;
  margin: 0 -2px;
  .ant-btn {
    color: var(--hsgreyDark);
    height: 22px;
    margin-bottom: 5px;
    padding: 0;
    text-align: left;
    display: flex;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      color: var(--hsblueDark);
    }
    .ant-btn-icon-wrapper {
      min-width: 18px;
      display: block;
    }
  }
}

::v-deep .custom-table {
  tbody {
    td {
      &:first-child {
        width: 200px;
      }
    }
  }
}

::v-deep .input-group--small .ant-select-selection {
  min-height: 205px;
}
</style>
